import { render, staticRenderFns } from "./default.vue?vue&type=template&id=47716bb2&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navigation: require('/var/www/elektriker.haandvaerker.dk/strapi-nuxt/components/navigation.vue').default,Sitefooter: require('/var/www/elektriker.haandvaerker.dk/strapi-nuxt/components/sitefooter.vue').default})
