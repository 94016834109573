
import navigation from '~/components/navigation.vue'
import sitefooter from '~/components/sitefooter.vue'

export default {
  data() {
    return {

    }
  },
  mounted () {
  },
  components: {
    navigation,
    sitefooter
  }
}
