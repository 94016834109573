import data from '~/static/el.content'
export const state = () => ({
  menu: data.menu,
  pages: data.pages,
  lang: 'en'
  // reviews: data.reviews,
})
export const mutations = {
  SET_MENU(state, menu) {
    state.menu = menu
  },
  setData(state, { storage, key, data }) {
    Vue.set(storage, key, data)
  }
}
