
export default {
  name: 'sitefooter',
  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
  methods: {
  }
}
